/* eslint-disable react/jsx-props-no-spreading */
import { InputHTMLAttributes } from 'react';

export type PlainInputProps = InputHTMLAttributes<HTMLInputElement>;

export default function PlainInput(props: PlainInputProps) {
  return (
    <input
      {...props}
      data-1p-ignore
      className="block w-full relative py-2 px-3 font-normal leading-normal text-black dark:text-white appearance-none bg-white dark:bg-neutral-800 bg-clip-padding border border-solid border-zinc-200 dark:border-zinc-600 rounded-md transition-colors focus:border-blue-300 focus:outline-0 focus:shadow disabled:bg-gray-300"
    />
  );
}

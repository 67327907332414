/* eslint-disable react/jsx-props-no-spreading */
import { useId } from 'react';
import Label from './Label';
import PlainInput, { PlainInputProps } from './PlainInput';

interface Props {
  label: string
}

export default function LabelAndInput({ label, ...rest }: Props & PlainInputProps) {
  const inputId = useId();

  return (
    <>
      <Label htmlFor={inputId}>{label}</Label>
      <PlainInput {...rest} id={inputId} />
    </>
  );
}

import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';

import '../shared/index.css';
import Root from '../modernwallet/Root';
import Scan from '../modernwallet/Scan';
import Pay from '../modernwallet/Pay';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
  },
  {
    path: '/scan',
    element: <Scan />,
  },
  {
    path: '/pay',
    element: <Pay />,
  },
]);

const appRoot = document.getElementById('app-root');

if (appRoot) {
  ReactDOM.createRoot(appRoot).render(
    <StrictMode>
      <RouterProvider router={router} />
    </StrictMode>,
  );
}

import { ReactNode } from 'react';

interface Props {
  htmlFor: HTMLLabelElement['htmlFor']
  children: ReactNode
}

export default function Label({ children, htmlFor }: Props) {
  return (
    <label htmlFor={htmlFor} className="dark:text-zinc-200">
      {children}
    </label>
  );
}

import { useLocation, useNavigate } from 'react-router-dom';
import Navbar from '../shared/Navbar';
import Button from '../shared/Button';
import Alert from '../shared/Alert';

export default function Root() {
  const location = useLocation();
  const navigate = useNavigate();
  // const [modifing, setModifying] = useState(false);
  // const [checking, setChecking] = useState(true);
  // const [buttonStyle, setButtonStyle] = useState<NonNullable<Props['buttonStyle']>>('secondary');
  // const [buttonText, setButtonText] = useState('Checking Registration…');
  const response = location.state as Record<string, string> | null;
  // const onClick = useCallback(() => {
  //   const modifyRegistration = async () => {
  //     setModifying(true);
  //     try {
  //       if (buttonStyle === 'danger') {
  //         const registration = await navigator.serviceWorker.getRegistration('/');
  //         await registration?.unregister();
  //         setButtonStyle('secondary');
  //         setButtonText('Install Handler');
  //       } else {
  //         await navigator.serviceWorker.register('/sw.js', {
  //           scope: '/',
  //           type: 'classic',
  //           updateViaCache: 'none',
  //         });
  //         setButtonStyle('danger');
  //         setButtonText('Remove Handler');
  //       }
  //     } finally {
  //       setModifying(false);
  //     }
  //   };

  //   modifyRegistration().catch(() => { console.error('modifyRegistration() failed'); });
  // }, [buttonStyle]);

  // useEffect(() => {
  //   const checkServiceWorker = async () => {
  //     try {
  //       const registration = await navigator.serviceWorker.getRegistration('/');
  //       if (registration) {
  //         setButtonText('Remove Handler');
  //         setButtonStyle('danger');
  //       } else {
  //         setButtonText('Install Handler');
  //       }
  //     } finally {
  //       setChecking(false);
  //     }
  //   };
  //   checkServiceWorker().catch(
  //     (error) => { console.error(`checkServiceWorker() failed [${error}]`); },
  //   );
  // }, []);

  let alertMessage;

  if (response?.reference != null) {
    alertMessage = `Your payment was successful. Your confirmation code is: ${response.reference}`;
  }

  return (
    <>
      <Navbar />
      <div className="w-full mx-auto px-3 mt-3 tablet:max-w-md">
        {response != null && alertMessage != null && <Alert message={alertMessage} />}
        <div className="grid gap-3">
          <Button onClick={() => { navigate('/scan'); }}>Scan QR Code</Button>
          <Button buttonStyle="secondary" onClick={() => { navigate('/pay'); }}>Pay</Button>
          {/* <Button buttonStyle={buttonStyle} disabled={checking || modifing} onClick={onClick}>
            {buttonText}
          </Button> */}
        </div>
      </div>
    </>
  );
}

import { useNavigate } from 'react-router-dom';
import {
  useEffect, useRef, useState,
} from 'react';
import useInterval from './useInterval';

export interface ScanResult {
  qr_code: string
}

export default function Scan() {
  const navigate = useNavigate();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [delay, setDelay] = useState<number | null>(null);
  const [barcodeDetector, setBarcodeDetector] = useState<BarcodeDetectorInstance>();

  useEffect(() => {
    async function loadDetector() {
      let BarcodeDetectorClass: BarcodeDetectorConstructor;
      if (window.BarcodeDetector == null) {
        const { BarcodeDetectorPolyfill } = await import('@undecaf/barcode-detector-polyfill');
        BarcodeDetectorClass = BarcodeDetectorPolyfill;
      } else {
        BarcodeDetectorClass = window.BarcodeDetector;
      }
      return new BarcodeDetectorClass({ formats: ['qr_code'] });
    }
    loadDetector().then(
      setBarcodeDetector,
      () => { console.error('loadDetector() failed'); },
    );
  }, []);

  useInterval(() => {
    const video = videoRef.current;

    if (video == null || barcodeDetector == null) {
      return;
    }

    barcodeDetector.detect(video).then((barcodes) => {
      if (barcodes.length > 0 && delay) {
        setDelay(null);
        const state: ScanResult = { qr_code: barcodes[0].rawValue };
        navigate('/pay', { state });
      }
    }, () => { console.error('detect() failed'); });
  }, delay);

  useEffect(() => {
    let exited = false;
    let stream: MediaStream | undefined;

    const cleanup = (s?: MediaStream) => {
      s?.getTracks().forEach((track) => { track.stop(); });
    };

    navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } }).then((mediaStream) => {
      if (exited || videoRef.current == null) {
        cleanup(mediaStream);
        return;
      }

      stream = mediaStream;

      videoRef.current.srcObject = mediaStream;
      videoRef.current.onloadedmetadata = () => {
        videoRef.current?.play().then(
          () => { setDelay(500); },
          (error) => { console.error(`play() failed [${error}]`); },
        );
      };
    }, () => { console.error('getUserMedia() failed'); });

    return () => {
      exited = true;
      cleanup(stream);
    };
  }, []);

  return (
    <div className="w-full h-screen">
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video playsInline muted className="w-full h-full object-cover bg-black" ref={videoRef} />
    </div>
  );
}

/* eslint-disable react/jsx-props-no-spreading */
import { InputHTMLAttributes, useId } from 'react';

interface Props {
  label: string
}

export default function Checkbox(
  { label, ...rest }: Props & InputHTMLAttributes<HTMLInputElement>,
) {
  const inputId = useId();

  return (
    <div className="block mb-1">
      <input {...rest} id={inputId} type="checkbox" className="rounded w-4 h-4 mt-1 align-top appearance-none bg-white dark:bg-neutral-800 bg-no-repeat bg-center bg-contain border border-zinc-200 dark:border-zinc-600 checked:bg-blue-600 checked:border-blue-600 checked:bg-checkbox-checked" />
      <label className="dark:text-zinc-200 ml-1" htmlFor={inputId}>{label}</label>
    </div>
  );
}

/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  FormEvent, useCallback, useEffect, useId, useMemo, useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import cc from 'currency-codes';
import { getData } from 'country-list';
import { ScanResult } from './Scan';
import Navbar from '../shared/Navbar';
import Button from '../shared/Button';
import Tag from '../shared/Tag';
import Label from './Label';
import LabelAndInput from './LabelAndInput';
import Checkbox from './Checkbox';

interface FrontendMessageResponse {
  type: string
  code?: string
  message?: string
}

export default function Root() {
  const amountId = useId();
  const merchantCountryListId = useId();

  const location = useLocation();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const scanResult = location.state as ScanResult | null;
  const [isEmbedded, setIsEmbedded] = useState(false);
  const [code, setCode] = useState(scanResult?.qr_code);

  useEffect(() => {
    navigator.serviceWorker.controller?.postMessage({
      type: 'ready',
      paymentRequestId: location.hash.slice(1),
    });
  }, [location.hash]);

  useEffect(() => {
    const handler = (e: MessageEvent<FrontendMessageResponse>) => {
      if (e.origin !== window.top?.origin) {
        return;
      }

      const { data } = e;

      switch (data.type) {
        case 'ready_response':
          setCode(data.code);
          setIsEmbedded(true);
          break;
        case 'error':
          console.error(data.message);
          break;
        default:
          break;
      }
    };

    navigator.serviceWorker.addEventListener('message', handler);

    return () => {
      navigator.serviceWorker.removeEventListener('message', handler);
    };
  }, []);

  const onSubmit = useCallback((event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);
    const body = new FormData(event.currentTarget);

    const headers = new Headers();

    const csrfTokenTag = document.querySelector('meta[name=\'csrf-token\']');
    if (csrfTokenTag instanceof HTMLMetaElement) {
      headers.set('X-CSRF-Token', csrfTokenTag.content);
    }

    fetch('/payments', { body, method: 'POST', headers }).then((response) => {
      if (response.status === 201) {
        response.json().then((state: Record<string, string>) => {
          if (isEmbedded) {
            navigator.serviceWorker.controller?.postMessage({
              type: 'paid',
              paymentRequestId: location.hash.slice(1),
            });
          } else {
            navigate('/', { state });
          }
        }, () => { console.error('json() failed'); });
      } else {
        setIsSubmitting(false);
      }
    }, () => { console.error('POST /payments failed'); });
  }, [isSubmitting, isEmbedded, navigate, location.hash]);

  const tagArray = useMemo(() => Tag.parse(code), [code]);
  const countries = useMemo(
    () => getData().map((c) => <option key={c.code} label={c.name} value={c.code} />),
    [],
  );

  let routingNumber: string | undefined;
  let accountNumber: string | undefined;
  let includeAddress = false as boolean;
  let includeEmail = false as boolean;
  let includePhone = false as boolean;

  tagArray.forEach((tag) => {
    const tagNumber = parseInt(tag.tag, 10);

    if (tagNumber >= 26 && tagNumber <= 51 && Tag.getStringValue('00', tag.value) === 'app.modernwallet') {
      routingNumber = Tag.getStringValue('01', tag.value);
      accountNumber = Tag.getStringValue('02', tag.value);
    } else if (tagNumber === 62) {
      const requests = Tag.getStringValue('09', tag.value) ?? '';
      includeAddress = requests.includes('A');
      includeEmail = requests.includes('E');
      includePhone = requests.includes('M');
    }
  });

  const currency = cc.number(Tag.getStringValue('53', tagArray) ?? '')?.code ?? 'USD';
  const amount = Tag.getStringValue('54', tagArray);
  const countryCode = Tag.getStringValue('58', tagArray);
  const merchantName = Tag.getStringValue('59', tagArray);
  const merchantCity = Tag.getStringValue('60', tagArray);

  return (
    <>
      <Navbar />
      <div className="w-full mx-auto px-3 mt-3 tablet:max-w-md">
        <form onSubmit={onSubmit}>
          <input type="hidden" name="qr_code" value={code} />
          <input type="hidden" name="currency" value={currency} />

          <div className="mb-3">
            <LabelAndInput label="Routing Number" name="routing_number" inputMode="numeric" readOnly={routingNumber != null} defaultValue={routingNumber} />
          </div>
          <div className="mb-3">
            <LabelAndInput label="Account Number" name="account_number" inputMode="numeric" readOnly={accountNumber != null} defaultValue={accountNumber} />
          </div>
          <div className="mb-3">
            <LabelAndInput label="Merchant Name" name="merchant_name" readOnly={merchantName != null} defaultValue={merchantName} />
          </div>
          {scanResult != null && (
            <>
              <div className="mb-3">
                <LabelAndInput label="Merchant City" name="merchant_city" readOnly={merchantCity != null} defaultValue={merchantCity} />
              </div>
              <div className="mb-3">
                <LabelAndInput label="Merchant Country" name="merchant_country" readOnly={countryCode != null} defaultValue={countryCode} list={merchantCountryListId} placeholder="Select a Country…" />
                <datalist id={merchantCountryListId}>
                  {countries}
                </datalist>
              </div>
            </>
          )}
          <div className="mb-3">
            <Label htmlFor={amountId}>Amount</Label>
            <div className="relative flex flex-wrap items-stretch w-full">
              <input id={amountId} name="amount" readOnly={amount != null} type="text" inputMode="decimal" className="block w-px min-w-0 flex-auto relative py-2 px-3 font-normal leading-normal text-black dark:text-white appearance-none bg-white dark:bg-neutral-800 bg-clip-padding border border-solid border-zinc-200 dark:border-zinc-600 rounded-md rounded-r-none transition-colors focus:border-blue-300 focus:outline-0 focus:shadow disabled:bg-gray-300" value={amount} />
              <span className="flex items-center py-1 px-3 -ml-px font-normal leading-normal text-black dark:text-zinc-200 text-center whitespace-nowrap bg-slate-50 dark:bg-gray-800 rounded-md rounded-l-none border border-zinc-200 dark:border-zinc-600">{currency}</span>
            </div>
          </div>
          {includeEmail && (
          <div className="mb-3">
            <Checkbox name="include_email" label="Include Email Address" defaultChecked />
          </div>
          )}
          {includePhone && (
          <div className="mb-3">
            <Checkbox name="include_phone" label="Include Phone Number" defaultChecked />
          </div>
          )}
          {includeAddress && (
          <div className="mb-3">
            <Checkbox name="include_address" label="Include Shipping Address" defaultChecked />
          </div>
          )}
          <div className="grid">
            <Button type="submit" disabled={isSubmitting}>{isSubmitting ? 'Paying…' : 'Pay'}</Button>
          </div>
        </form>
      </div>
    </>
  );
}
